import React from 'react';
import PropTypes from 'prop-types';
import {
  IconGitHub,
  IconLinkedin,
  IconFacebook,
  IconInstagram,
  IconTwitter,
  IconWordpress,
} from '@components/icons';
import { socialMedia } from '@config';
import styled from 'styled-components';
import { theme, mixins, media } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled.footer`
  ${mixins.flexCenter};
  flex-direction: column;
  padding: 15px;
  background-color: ${colors.dark};
  color: ${colors.slate};
  text-align: center;
  height: auto;
  min-height: 70px;
`;
const StyledSocial = styled.div`
  color: ${colors.lightSlate};
  width: 100%;
  max-width: 270px;
  margin: 0 auto 10px;
  display: none;
  ${media.tablet`display: block;`};
  z-index: 12;
`;
const StyledSocialList = styled.ul`
  ${mixins.flexBetween};
  padding: 0;
  margin: 0;
  list-style: none;
`;
const StyledSocialLink = styled.a`
  padding: 10px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
const StyledMetadata = styled.div`
  font-family: ${fonts.SFMono};
  font-size: ${fontSizes.xs};
  line-height: 1;
`;
const StyledGitHubLink = styled.a`
  color: ${colors.slate};
  padding: 10px;
  svg {
    width: 20px;
    margin-right: 5px;
  }
  span {
    margin-left: 20px;
  }
`;
// const StyledGitHubInfo = styled.div`
//   margin-top: 10px;

//   & > span {
//     display: inline-flex;
//     align-items: center;
//     margin: 0 7px;
//   }
//   svg {
//     display: inline-block;
//     height: 15px;
//     width: auto;
//     margin-right: 5px;
//   }
// `;
const d = new Date();
const year = d.getFullYear();

const Footer = () => (
  // const [githubInfo, setGitHubInfo] = useState({
  //   stars: null,
  //   forks: null,
  // });

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     return;
  //   }
  //   fetch('https://api.github.com/repos/juanjotorres90/NBA-Players-Data')
  //     .then(response => response.json())
  //     .then(json => {
  //       const { stargazers_count, forks_count } = json;
  //       setGitHubInfo({
  //         stars: stargazers_count,
  //         forks: forks_count,
  //       });
  //     })
  //     .catch(e => console.error(e));
  // }, []);

  <StyledContainer>
    <StyledSocial>
      <StyledSocialList>
        {socialMedia &&
          socialMedia.map(({ name, url }, i) => (
            <li key={i}>
              <StyledSocialLink
                href={url}
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label={name}>
                {name === 'GitHub' ? (
                  <IconGitHub />
                ) : name === 'Linkedin' ? (
                  <IconLinkedin />
                ) : name === 'Wordpress' ? (
                  <IconWordpress />
                ) : name === 'Facebook' ? (
                  <IconFacebook />
                ) : name === 'Instagram' ? (
                  <IconInstagram />
                ) : name === 'Twitter' ? (
                  <IconTwitter />
                ) : (
                  <IconGitHub />
                )}
              </StyledSocialLink>
            </li>
          ))}
      </StyledSocialList>
    </StyledSocial>
    <StyledMetadata tabindex="-1">
      <StyledGitHubLink
        href="https://github.com/juanjotorres90"
        target="_blank"
        rel="nofollow noopener noreferrer">
        <div>
          <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="#fff">
            <path d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.640625-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.640625 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-472c-119.101562 0-216 96.898438-216 216s96.898438 216 216 216 216-96.898438 216-216-96.898438-216-216-216zm105.359375 321.359375-28.285156-28.285156c-42.5 42.5-111.648438 42.5-154.148438 0s-42.5-111.648438 0-154.148438 111.648438-42.5 154.148438 0l28.285156-28.285156c-58.097656-58.09375-152.625-58.09375-210.71875 0-58.09375 58.097656-58.09375 152.625 0 210.71875 29.046875 29.046875 67.203125 43.570313 105.359375 43.570313 38.152344 0 76.3125-14.523438 105.359375-43.570313zm0 0" />
          </svg>
          {year}
          <span>Juan José Torres</span>
        </div>
        {/* 
          {githubInfo.stars && githubInfo.forks && (
            <StyledGitHubInfo>
              <span>
                <IconStar />
                <span>{githubInfo.stars}</span>
              </span>
              <span>
                <IconFork />
                <span>{githubInfo.forks}</span>
              </span>
            </StyledGitHubInfo>
          )} */}
      </StyledGitHubLink>
    </StyledMetadata>
  </StyledContainer>
);
Footer.propTypes = {
  githubInfo: PropTypes.object,
};

export default Footer;
