import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import sr from '@utils/sr';
import { srConfig, github } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Section, Heading } from '@styles';
const { colors } = theme;

const StyledContainer = styled(Section)`
  position: relative;
  // width: 70%;
  max-width: 910px;
`;
const StyledFlexContainer = styled.div`
  ${mixins.flexCenter};
  align-items: center;
  ${media.tablet`display: block;
  padding: 0px 0;
  `};
  padding: 75px 0;
`;
const StyledContent = styled.div`
  margin-left: 10%;
  width: 60%;
  max-width: 480px;
  text-align: justify;
  ${media.tablet`width: 100%;
    margin: 40px auto 0 auto;
  `};
  a {
    ${mixins.inlineLink};
  }
`;
// const SkillsContainer = styled.ul`
//   display: grid;
//   grid-template-columns: repeat(2, minmax(140px, 200px));
//   overflow: hidden;
//   padding: 0;
//   margin: 20px 0 0 0;
//   list-style: none;
// `;
// const Skill = styled.li`
//   position: relative;
//   margin-bottom: 10px;
//   padding-left: 20px;
//   font-family: ${fonts.SFMono};
//   font-size: ${fontSizes.smish};
//   color: ${colors.slate};
//   &:before {
//     content: '▹';
//     position: absolute;
//     left: 0;
//     color: ${colors.green};
//     font-size: ${fontSizes.sm};
//     line-height: 12px;
//   }
// `;
const StyledPic = styled.div`
  border-radius: 50%;
  position: relative;
  width: 40%;
  max-width: 300px;
  margin-left: 60px;
  ${media.tablet`margin: 60px auto 0;`};
  ${media.phablet`width: 70%;`};
  a {
    &:focus {
      outline: 0;
    }
  }
`;
const StyledAvatar = styled(Img)`
  position: relative;
  // mix-blend-mode: multiply;
  filter: grayscale(40%) contrast(1);
  border-radius: 50%;
  transition: ${theme.transition};
  img {
    border-radius: 50%;
  }
`;
const StyledAvatarLink = styled.a`
  ${mixins.boxShadow};
  width: 100%;
  transition: all 0.5s;
  position: relative;
  border-radius: 50%;
  background-color: ${colors.green};
  margin-left: -20px;
  &:hover,
  &:focus {
    background: transparent;
    &:after {
      top: 0px;
      left: 0px;
    }
    ${StyledAvatar} {
      filter: none;
      mix-blend-mode: normal;
    }
  }
  &:before,
  &:after {
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: ${theme.transition};
  }
  &:before {
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.navy};
    mix-blend-mode: screen;
  }
  &:after {
    border-radius: 50%;
    // border: 10px solid ${colors.green};
    top: 0px;
    left: 0px;
    z-index: -1;
  }
  img {
    border: 3px solid ${colors.lightSlate};
  }
  img:hover {
    border: 5px solid ${colors.lightSlate};
    // transition: all 3s;
  }
  ${media.tablet`margin: 0 auto;`};

`;

const About = ({ data }) => {
  const { frontmatter, html } = data[0].node;
  const { title, avatar } = frontmatter;
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <StyledContainer id="about" ref={revealContainer}>
      <Heading>{title}</Heading>
      <StyledFlexContainer>
        <StyledPic>
          <StyledAvatarLink href={github}>
            <StyledAvatar fluid={avatar.childImageSharp.fluid} alt="Avatar" />
          </StyledAvatarLink>
        </StyledPic>

        <StyledContent>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {/* <SkillsContainer>
            {skills && skills.map((skill, i) => <Skill key={i}>{skill}</Skill>)}
          </SkillsContainer> */}
        </StyledContent>
      </StyledFlexContainer>
    </StyledContainer>
  );
};

About.propTypes = {
  data: PropTypes.array.isRequired,
};

export default About;
