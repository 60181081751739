import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import sr from '@utils/sr';
import { srConfig } from '@config';
// import { IconGitHub, IconExternal, IconFolder } from '@components/icons';
import styled from 'styled-components';
import { theme, mixins, media, Section } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: center;
  padding-top: 0;
`;
// const StyledTitle = styled.h4`
//   margin: 0 auto;
//   font-size: ${fontSizes.h3};
//   ${media.tablet`font-size: 24px;`};
//   a {
//     display: block;
//   }
// `;
// const StyledArchiveLink = styled(Link)`
//   ${mixins.inlineLink};
//   text-align: center;
//   margin: 0 auto;
//   font-family: ${fonts.SFMono};
//   font-size: ${fontSizes.sm};
//   &:after {
//     bottom: 0.1em;
//   }
// `;
const StyledGrid = styled.div`
  margin-top: 50px;
  width: 100%;
  .projects {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
    ${media.desktop`grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));`};
  }
`;
const StyledProjectInner = styled.div`
  ${mixins.boxShadow};
  ${mixins.flexBetween};
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 1rem 1.75rem;
  height: 100%;
  min-height: 129px;
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};
  background-color: ${colors.transGreen};
  // border: 1px solid ${colors.lightSlate};
  text-align: center;
  header,
  footer {
    width: 100%;
  }
`;
const StyledProject = styled.div`
  transition: ${theme.transition};
  min-height: 129px;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: 0;
    ${StyledProjectInner} {
      transform: scale(0.98);
    }
  }
`;
const StyledProjectHeader = styled.div`
  ${mixins.flexBetween};
  // margin-bottom: 30px;
`;
// const StyledFolder = styled.div`
//   color: ${colors.green};
//   svg {
//     width: 40px;
//     height: 40px;
//   }
// `;
// const StyledProjectLinks = styled.div`
//   margin-right: -10px;
//   color: ${colors.lightSlate};
// `;
// const StyledIconLink = styled.a`
//   position: relative;
//   top: -10px;
//   padding: 10px;
//   svg {
//     width: 20px;
//     height: 20px;
//   }
// `;
const StyledProjectName = styled.h5`
  margin: 0 0 10px;
  font-size: ${fontSizes.xxl};
  color: ${colors.lightestSlate};
  &:hover {
    color: ${colors.green};
  }
`;
// const StyledProjectDescription = styled.div`
//   font-size: 17px;
//   color: ${colors.lightSlate};
//   a {
//     ${mixins.inlineLink};
//   }
// `;
const StyledTechList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0;
  margin: 10px 0 0 0;
  list-style: none;

  li {
    font-family: ${fonts.SFMono};
    font-size: ${fontSizes.xs};
    color: ${colors.slate};
    line-height: 1.75;
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
// const StyledMoreButton = styled(Button)`
//   margin: 100px auto 0;
// `;

const Projects = ({ data }) => {
  const [showMore] = useState(false);
  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealProjects = useRef([]);

  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 9;
  const projects = data.filter(({ node }) => node);
  const firstSix = projects.slice(0, GRID_LIMIT);
  const projectsToShow = showMore ? projects : firstSix;

  return (
    <StyledContainer>
      {/* <StyledTitle ref={revealTitle}>Other Noteworthy Projects</StyledTitle>
      <StyledArchiveLink to="/archive" ref={revealArchiveLink}>
        view the archive
      </StyledArchiveLink> */}

      <StyledGrid>
        <TransitionGroup className="projects">
          {projectsToShow &&
            projectsToShow.map(({ node }, i) => {
              const { frontmatter } = node;
              const { external, title, tech } = frontmatter;
              return (
                <CSSTransition
                  key={i}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <a
                    href={external}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label="External Link">
                    <StyledProject
                      key={i}
                      ref={el => (revealProjects.current[i] = el)}
                      tabIndex="0"
                      style={{
                        transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                      }}>
                      <StyledProjectInner>
                        <header>
                          <StyledProjectHeader>
                            {/* <StyledFolder>
                            <IconFolder />
                          </StyledFolder> */}
                            {/* <StyledProjectLinks>
                            {github && (
                              <StyledIconLink
                                href={github}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                aria-label="GitHub Link">
                                <IconGitHub />
                              </StyledIconLink>
                            )}
                            {external && (
                              <StyledIconLink
                                href={external}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                aria-label="External Link">
                                <IconExternal />
                              </StyledIconLink>
                            )}
                          </StyledProjectLinks> */}
                          </StyledProjectHeader>
                          <StyledProjectName>{title}</StyledProjectName>
                          {/* <StyledProjectDescription dangerouslySetInnerHTML={{ __html: html }} /> */}
                        </header>
                        <footer>
                          <StyledTechList>
                            {tech.map((tech, i) => (
                              <li key={i}>{tech}</li>
                            ))}
                          </StyledTechList>
                        </footer>
                      </StyledProjectInner>
                    </StyledProject>
                  </a>
                </CSSTransition>
              );
            })}
        </TransitionGroup>
      </StyledGrid>

      {/* <StyledMoreButton onClick={() => setShowMore(!showMore)}>
        Show {showMore ? 'Less' : 'More'}
      </StyledMoreButton> */}
    </StyledContainer>
  );
};

Projects.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Projects;
