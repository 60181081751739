import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import sr from '@utils/sr';
import { srConfig, email } from '@config';
import styled from 'styled-components';
import { mixins, media, Section, theme } from '@styles';
const { colors } = theme;

// const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
  text-align: center;
  max-width: 600px;
  margin: 0 auto 100px;
  // a {
  //   ${mixins.inlineLink};
  // }
  .icons {
    margin-top: 100px
  }
`;
// const StyledHeading = styled(Heading)`
//   display: block;
//   color: ${colors.green};
//   font-size: ${fontSizes.md};
//   font-family: ${fonts.SFMono};
//   font-weight: normal;
//   margin-bottom: 20px;
//   justify-content: center;
//   ${media.desktop`font-size: ${fontSizes.sm};`};
//   &:before {
//     bottom: 0;
//     font-size: ${fontSizes.sm};
//     ${media.desktop`font-size: ${fontSizes.smish};`};
//   }
//   &:after {
//     display: none;
//   }
// `;
const StyledTitle = styled.h4`
  margin: 0 0 20px;
  font-size: 60px;
  ${media.desktop`font-size: 50px;`};
  ${media.tablet`font-size: 40px;`};
`;
const StyledEmailLink = styled.a`
  // margin-top: 50px;
  svg {
    width: 50px;
  }
  svg:hover {
    fill: ${colors.green};
  }
  .skype {
    padding-top: 10px;
    margin-left: 30px;
  }
`;

const Contact = ({ data }) => {
  const { frontmatter, html } = data[0].node;
  const { title } = frontmatter;
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <StyledContainer id="contact" ref={revealContainer}>
      {/* <StyledHeading>What&apos;s Next?</StyledHeading> */}

      <StyledTitle>{title}</StyledTitle>

      <div dangerouslySetInnerHTML={{ __html: html }} />

      <div className="icons">
        <StyledEmailLink
          href={`mailto:${email}`}
          target="_blank"
          rel="nofollow noopener noreferrer"
          title="Send me an email">
          {/* <img src="/images/email.svg" alt="Email" title="Send me an email"></img> */}
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#a8b2d1"
            viewBox="0 0 512 512"
            className="email">
            <path d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106 C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787	L338.213,256L482,112.212V399.787z" />
          </svg>
        </StyledEmailLink>
        <StyledEmailLink
          href="skype:live:juanjotorres_1?call"
          target="_blank"
          rel="nofollow noopener noreferrer"
          title="Call me on Skype">
          {/* <img src="/images/skype.svg" alt="Skype" title="Call me on Skype"></img> */}
          <svg
            id="Bold"
            xmlns="http://www.w3.org/2000/svg"
            fill="#a8b2d1"
            viewBox="0 0 30 30"
            className="skype">
            <path d="m23.309 14.547c1.738-7.81-5.104-14.905-13.139-13.543-4.362-2.707-10.17.352-10.17 5.542 0 1.207.333 2.337.912 3.311-1.615 7.828 5.283 14.821 13.311 13.366 5.675 3.001 11.946-2.984 9.086-8.676zm-7.638 4.71c-2.108.867-5.577.872-7.676-.227-2.993-1.596-3.525-5.189-.943-5.189 1.946 0 1.33 2.269 3.295 3.194.902.417 2.841.46 3.968-.3 1.113-.745 1.011-1.917.406-2.477-1.603-1.48-6.19-.892-8.287-3.483-.911-1.124-1.083-3.107.037-4.545 1.952-2.512 7.68-2.665 10.143-.768 2.274 1.76 1.66 4.096-.175 4.096-2.207 0-1.047-2.888-4.61-2.888-2.583 0-3.599 1.837-1.78 2.731 2.466 1.225 8.75.816 8.75 5.603-.005 1.992-1.226 3.477-3.128 4.253z" />
          </svg>
        </StyledEmailLink>
      </div>
    </StyledContainer>
  );
};

Contact.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Contact;
