module.exports = {
  siteTitle: 'Juanjo Torres | Front-End Developer',
  siteDescription:
    'Juanjo Torres is a Front-End developer based in Barcelona, building awesome websites and applications.',
  siteKeywords:
    'Juanjo Torres, Juanjo, Torres, juanjotorres90, software engineer, front-end engineer, web developer, javascript, spain, barcelona, front end developer',
  siteUrl: 'https://juanjotorres.net',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-140510560-2',
  googleVerification: '',
  name: 'Juanjo Torres',
  location: 'Barcelona, Spain',
  email: 'juanjotorres90@gmail.com',
  github: 'https://github.com/juanjotorres90',
  twitterHandle: '@juanjocodes',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/juanjotorres90',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/juanjotorreslopez',
    },
    {
      name: 'Wordpress',
      url: 'https://juanjotorres.net/blog/',
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/webdevJuanjo/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/juanjo.codes',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/juanjocodes',
    },
  ],

  navLinks: [
    {
      src: '/images/man.svg',
      url: '/#about',
      name: 'About me',
    },
    {
      src: '/images/programador.svg',
      name: 'Experience',
      url: '/#jobs',
    },
    {
      src: '/images/proyecto.svg',
      name: 'Projects',
      url: '/#projects',
    },
    {
      src: '/images/contact.svg',
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,
  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
