import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { theme, mixins, media, Section } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
  ${media.tablet`padding-top: 150px;`};
  div {
    width: 100%;
  }
`;
const StyledOverline = styled.h1`
  width: 100%;
  // display: inline-block;
  text-align: right;

  color: ${colors.green};
  margin: 0 0 20px 3px;
  font-size: ${fontSizes.md};
  font-family: ${fonts.SFMono};
  font-weight: normal;
  ${media.desktop`font-size: ${fontSizes.sm};`};
  ${media.tablet`font-size: ${fontSizes.smish};`};
`;
const StyledTitle = styled.h2`
  width: 100%;
  // display: inline-block;
  text-align: right;
  font-size: 80px;
  font-family: ${fonts.Poiret};
  line-height: 1.1;
  margin: 0 0 3rem 0;
  ${media.desktop`font-size: 70px;`};
  ${media.tablet`font-size: 60px;`};
  ${media.phablet`font-size: 50px;`};
  ${media.phone`font-size: 40px;`};
`;
const StyledSubtitle = styled.h3`
  font-size: 80px;
  font-family: ${fonts.Poiret};
  line-height: 1.1;
  color: ${colors.slate};
  ${media.desktop`font-size: 70px;`};
  ${media.tablet`font-size: 60px;`};
  ${media.phablet`font-size: 50px;`};
  ${media.phone`font-size: 40px;`};
`;
const StyledDescription = styled.div`
  margin-top: 25px;
  width: 50%;
  max-width: 500px;
  a {
    ${mixins.inlineLink};
  }
`;
const StyledEmailLink = styled.a`
  // ${mixins.bigButton};
  // width: 100%;
  // text-align: center;
  margin-top: auto;
  & svg {
    width: 60px;
  }
  position: absolute;
bottom: 3rem;
right: 20%;
`;

const Hero = ({ data }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);
    return () => clearTimeout(timeout);
  }, []);

  const { frontmatter, html } = data[0].node;

  // const onMouseEnterH1 = () => {
  //   document.querySelector('.gGfdKB').innerHTML = 'General Kenobi';
  // };

  // const onMouseLeaveH1 = () => {
  //   document.querySelector('.gGfdKB').innerHTML = frontmatter.title;
  // };

  const one = () => (
    <StyledOverline
      style={{ transitionDelay: '100ms' }}
      // onMouseOver={onMouseEnterH1}
      // onMouseLeave={onMouseLeaveH1}
      // onFocus={onMouseEnterH1}
    >
      {frontmatter.title}
    </StyledOverline>
  );
  const two = () => (
    <StyledTitle style={{ transitionDelay: '200ms' }}>{frontmatter.name}</StyledTitle>
  );
  const three = () => (
    <StyledSubtitle style={{ transitionDelay: '300ms' }}>{frontmatter.subtitle}</StyledSubtitle>
  );
  const four = () => (
    <StyledDescription
      style={{ transitionDelay: '400ms' }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
  const five = () => (
    // <div style={{ transitionDelay: '500ms' }}>
    <StyledEmailLink href="#about" title="Go">
      <svg
        fill="#000"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512">
        <path
          d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M256,490.667
				C126.604,490.667,21.333,385.396,21.333,256S126.604,21.333,256,21.333S490.667,126.604,490.667,256S385.396,490.667,256,490.667
				z"
        />
        <path
          d="M344.458,291.125l-77.792,77.792V117.333c0-5.896-4.771-10.667-10.667-10.667s-10.667,4.771-10.667,10.667v251.583
				l-77.792-77.792c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l96,96
				c2.083,2.083,4.813,3.125,7.542,3.125s5.458-1.042,7.542-3.125l96-96c4.167-4.167,4.167-10.917,0-15.083
				S348.625,286.958,344.458,291.125z"
        />
      </svg>
    </StyledEmailLink>
    // </div>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledContainer>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={2000}>
              {item}
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledContainer>
  );
};

Hero.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Hero;
