import styled from 'styled-components';
import theme from './theme';
import media from './media';
const { colors, fontSizes, fonts } = theme;

const Heading = styled.h3`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0 40px;
  width: 100%;
  white-space: nowrap;
  font-size: ${fontSizes.h3};
  ${media.tablet`font-size: 24px;`};

  // &:before {
  //   counter-increment: section;
  //   content: '0' counter(section) '.';
  //   margin-right: 10px;
  //   font-family: ${fonts.SFMono};
  //   font-weight: normal;
  //   color: ${colors.green};
  //   font-size: ${fontSizes.xl};
  //   position: relative;
  //   bottom: 4px;
  //   ${media.tablet`font-size: ${fontSizes.lg};`};
  // }

  &:before {
    content: '';
    display: block;
    height: 1px;
    width: 50%;
    background-color: ${colors.mediumGrey};
    position: relative;
    top: 0px;
    margin-right: 20px;
    ${media.desktop`width: 200px`};
    ${media.tablet`width: 100%;`};
    ${media.thone`margin-left: 10px;`};
  }

  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 50%;
    background-color: ${colors.mediumGrey};
    position: relative;
    top: 0px;
    margin-left: 20px;
    ${media.desktop`width: 200px`};
    ${media.tablet`width: 100%;`};
    ${media.thone`margin-left: 10px;`};
  }
`;

export default Heading;
